<template>
    <v-card>
        <v-toolbar>
        <span class="headline">{{trailer.registration}}</span>
        <v-spacer></v-spacer>

        <v-btn @click="closeTrailer" icon>
          <v-icon color="red">close</v-icon>
        </v-btn>
      </v-toolbar>
      
      <v-card-text>
        <v-row>
        <v-col cols="12" md="6" sm="6">
        <v-col cols="12" md="12" sm="12">
        <v-text-field
        v-model="trailer.registration"
        label="Registration"
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12">
        <v-text-field
        v-model="trailer.type"
        label="Trailer Type"
        dense
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12">
        <v-text-field
        v-model="trailer.model"
        label="Model"
        dense
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12">
        <v-text-field
        v-model="trailer.make"
        label="Make"
        dense
        ></v-text-field>
    </v-col>
</v-col>
    <v-col cols="12" md="6" sm="6">
        <v-col cols="12" md="12" sm="12">
        <v-text-field
        v-model="trailer.loadCapacity"
        label="Load Capacity"
        ></v-text-field>
    </v-col>
        <v-col cols="12" md="12" sm="12">
        <v-text-field
        v-model="trailer.axles"
        label="Axles"
        dense
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12">
        <v-text-field
        v-model="trailer.length"
        label="Trailer Length"
        dense
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="12" sm="12">
        <v-text-field
        v-model="trailer.wheelbase"
        label="Wheelbase"
        dense
        ></v-text-field>
    </v-col>

    </v-col>
</v-row>
      </v-card-text>
    </v-card>
</template>

<script>
export default {
props: ['trailer'],
data: () => ({
    
}),
methods: {
    closeTrailer() {
        this.$emit('close')
    }
}
}
</script>